<template>
    <div class="container">
      <h1>Privacy Policy</h1>
      <p>Last updated: October 10, 2024</p>
  
      <p>This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and informs You about Your privacy rights and how the law protects You.</p>
  
      <p>We use Your Personal Data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>
  
      <h2>Interpretation and Definitions</h2>
  
      <h3>Interpretation</h3>
      <p>The words with initial letters capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
  
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
  
      <ul>
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> refers to any external organization or research collaborator with whom the Research Team may collaborate for the purposes of this research.</li>
        <li><strong>Application</strong> refers to <strong>CIR App</strong>, the software program used in the research project on hoarding disorder.</li>
        <li><strong>Research Team</strong> (referred to as "We", "Us", or "Our" in this Privacy Policy) refers to the research group responsible for developing and administering the CIR App for research purposes.</li>
        <li><strong>Country</strong> refers to: Massachusetts, United States.</li>
        <li><strong>Device</strong> means any device that can access the Service, such as a computer, cellphone, or digital tablet.</li>
        <li><strong>Personal Data</strong> refers to any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service</strong> refers to the CIR App.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Research Team. This refers to third-party organizations or individuals employed by the Research Team to facilitate the Service, provide the Service on behalf of the Research Team, perform services related to the Service, or assist in analyzing how the Service is used.</li>
        <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
        <li><strong>You</strong> refers to the individual accessing or using the Service, or the organization, institution, or legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>
  
      <h2>Collecting and Using Your Personal Data</h2>
  
      <h3>Types of Data Collected</h3>
  
      <h4>Personal Data</h4>
      <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
  
      <ul>
        <li>Email address</li>
        <li>Usage Data</li>
      </ul>
  
      <h4>Usage Data</h4>
      <p>Usage Data is collected automatically when using the Service.</p>
  
      <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
  
      <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers, and other diagnostic data.</p>
  
      <h3>Information Collected while Using the Application</h3>
      <p>While using Our Application, and with Your prior permission, We may collect:</p>
  
      <ul>
        <li>Pictures and other information from your Device's camera and photo library.</li>
      </ul>
  
      <p>We use this information to provide features of Our Service and to improve and customize Our Service. The information may be uploaded to the Research Team's secure servers or stored on Your device.</p>
  
      <p>You can enable or disable access to this information at any time through Your Device settings.</p>
  
      <h2>Use of Your Personal Data</h2>
      <p>The Research Team may use Personal Data for the following purposes:</p>
  
      <ul>
        <li><strong>To provide and maintain our Service</strong>, including monitoring the usage of our Service.</li>
        <li><strong>To manage Your Account</strong>: to manage Your registration as a participant in the study. The Personal Data You provide allows You access to certain research-related functionalities of the Service as a registered user.</li>
        <li><strong>For research purposes</strong>: to analyze the data collected to advance research in hoarding disorder.</li>
        <li><strong>To contact You</strong>: To contact You by email or through the Service (e.g., push notifications) regarding updates or communications related to the research study, including security updates, where necessary.</li>
        <li><strong>To manage Your requests</strong>: To address and manage requests made to Us related to Your participation in the study.</li>
        <li><strong>For other research purposes</strong>: We may use Your information to analyze data trends, assess the effectiveness of research methodologies, and improve the study's design and Your experience.</li>
      </ul>
  
      <h2>Retention of Your Personal Data</h2>
      <p>The Research Team will retain Your Personal Data only for as long as is necessary for the purposes of the study, and in compliance with any applicable ethical guidelines or legal obligations.</p>
  
      <p>Usage Data is generally retained for a shorter period unless used for research analysis or to improve the Service's security or functionality.</p>
  
      <h2>Transfer of Your Personal Data</h2>
      <p>Your information, including Personal Data, is processed at the Research Team's operating offices and other locations where the processing parties are based. This means Your information may be transferred to and maintained on computers located outside Your state, province, or country, where data protection laws may differ.</p>
  
      <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to this transfer.</p>
  
      <h2>Deleting Your Personal Data</h2>
      <p>You have the right to delete or request that We assist in deleting the Personal Data collected about You. You can delete data through the Service or contact Us for assistance.</p>
  
      <p>However, certain data may need to be retained due to legal or ethical obligations.</p>
  
      <h2>Disclosure of Your Personal Data</h2>
  
      <h3>We may disclose Your Personal Data under the following circumstances:</h3>
  
      <ul>
        <li><strong>With Service Providers</strong>: We may share data with Service Providers to facilitate the research.</li>
        <li><strong>For research dissemination</strong>: Anonymous or aggregated data may be shared in research publications, presentations, or reports.</li>
        <li><strong>With Your consent</strong>: We may disclose Your Personal Data for any other purpose with Your explicit consent.</li>
      </ul>
  
      <h2>Security of Your Personal Data</h2>
      <p>We take reasonable measures to protect the confidentiality and security of Your Personal Data. However, no method of transmission over the internet or electronic storage is entirely secure, and We cannot guarantee its absolute security.</p>
  
      <h2>Children's Privacy</h2>
      <p>Our Service is not intended for children under the age of 13. We do not knowingly collect data from children under 13 without parental consent. If You believe Your child has provided Us with Personal Data, please contact Us, and We will take steps to delete the information.</p>
  
      <h2>Links to Other Websites</h2>
      <p>Our Service may contain links to other websites not operated by Us. We strongly recommend reviewing the Privacy Policy of every external site You visit.</p>
  
      <p>We assume no responsibility for the content, privacy policies, or practices of third-party websites or services.</p>
  
      <h2>Changes to this Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify You of any changes by posting the updated Privacy Policy on this page and alerting You via email or through the Service prior to the changes taking effect.</p>
  
      <p>Changes are effective when posted on this page.</p>
  
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, you can contact us:</p>
  
      <ul>
        <li>By email: <a href="mailto:support@cir-app.com">support@cir-app.com</a></li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  }
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  h1, h2, h3, h4 {
    color: #333;
  }
  
  p, ul, li {
    color: #555;
    line-height: 1.6;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  