import { createApp } from 'vue'
import App from './App.vue'
import Router from '@/router/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCryptojs from 'vue-cryptojs'

// PrimeFlex & PrimeIcons
import PrimeFlex from 'primeflex/primeflex.css'
import 'primeicons/primeicons.css';

// Global CSS
import '@/assets/style.scss'

// PrimeVue
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/mdc-light-indigo/theme.css'
import Divider from 'primevue/divider'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice';

// Pinia
import { createPinia } from 'pinia';

const app = createApp(App);
const pinia = createPinia()

app.use(pinia)
app.use(PrimeFlex);
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
app.use(VueCryptojs)

app.use(PrimeVue, { ripple: true, inputStyle: 'filled' });
app.use(ConfirmationService)
app.use(ToastService)
app.component('pv-Divider', Divider)    // divider has to be global for unknown reason

app.use(Router);

app.mount('#app');
