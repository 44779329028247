<template>
  <main class="fullscreen py-6 grid justify-content-center align-content-center">
    <!-- Left Side -->
    <div class="col-6 grid justify-content-center align-content-center m-auto">
      <img class="col-8 align-self-center" src="@/assets/logo.png" />
      <div class="col-4 justify-content-center" id="titleWrapper">
        <h1 id="title">The CIR App</h1>
        <h2 class="text-700 mt-1" id="title">Photo management portal</h2>
      </div>
    </div>

    <!-- Vertical Divider -->
    <pv-Divider class="col-1" layout="vertical"></pv-Divider>

    <!-- Right Side -->
    <div class="px-5 col-4 align-items-start m-auto">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'fade'">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </main>
</template>

<style lang="scss" scoped>
img {
  border-radius: 50%;
  max-width: var(--logo-size);
  max-height: var(--logo-size);
}

#title {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

#titleWrapper {
  margin-top: auto;
  margin-bottom: auto;
}

// transition animation
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-40%);
}
</style>
