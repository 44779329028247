<template>
  <div class="wrapper">
    <!-- Toast -->
    <Toast position="bottom-right" />

    <!-- Top -->
    <div class="w-full flex">
      <img class="border-circle align-self-center" :src="userAvatarUrl" :onerror="avatarError" :hidden="noAvatarFlag" />
      <div :hidden="!noAvatarFlag">
        <Avatar icon="pi pi-user" size="xlarge" shape="circle"></Avatar>
      </div>
      <div class="ml-5 flex-grow-1 flex flex-column justify-item-center m-auto">
        <p class="text-4xl">{{ userInfo.userName }}</p>
      </div>
    </div>

    <!-- TabView and settings -->
    <TabView class="tabview-custom mt-4">
      <!-- Change avatar -->
      <TabPanel>
        <template #header>
          <span class="material-icons-outlined mr-1">account_circle</span>
          <span>Change avatar</span>
        </template>
        <FileUpload mode="basic" accept="image/*" :customUpload="true" @uploader="uploadAvatar"></FileUpload>
      </TabPanel>

      <!-- Change username -->
      <TabPanel>
        <template #header>
          <span class="material-icons-outlined mr-1">badge</span>
          <span>Change username</span>
        </template>
        <InputText type="text" v-model="newUsername"></InputText>
        <Button class="ml-3" icon="pi pi-save" label="Save" :disabled="chngUrnmBtnDisabled"
          @click="changeUsername"></Button>
      </TabPanel>

      <!-- Change password -->
      <TabPanel>
        <template #header>
          <span class="material-icons-outlined mr-1">lock</span>
          <span>Change password</span>
        </template>
        <div class="flex flex-column" id="changePasswordWrapper">
          <InputText type="password" class="mb-2 w-full" placeholder="Old password" v-model="oldPassword"></InputText>
          <Password class="w-full mt-2 mb-2" placeholder="New password" :style="{ width: '100 %' }"
            v-model="newPassword" strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})" :class="passwordError"
            toggleMask>
            <template #footer>
              <pv-Divider />
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
                <li>Maximum 32 characters</li>
              </ul>
            </template>
          </Password>
          <div class="flex">
            <div class="flex-grow-1" style="width: 1px;"></div>
            <small class="p-error" :hidden="showPasswordError">Password doesn't meet requirement</small>
          </div>
          <InputText type="password" class="w-full mt-2 mb-2" placeholder="Confirm password"
            :class="confirmPasswordError" v-model="confirmPassword">
          </InputText>
          <div class="flex">
            <div class="flex-grow-1" style="width: 1px;"></div>
            <small class="p-error" :hidden="showConfirmError">Passwords don't match</small>
          </div>
          <Button class="mt-2" icon="pi pi-save" label="Save" :disabled="!enableChange"
            @click="changePassword"></Button>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { useCookies } from 'vue3-cookies';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FileUpload from 'primevue/fileupload'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Password from 'primevue/password'
import Avatar from 'primevue/avatar'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

import { userInfoStore } from '@/components/SidebarMenu.vue';
import { computed } from '@vue/reactivity';
import { web_users_getAvatar, web_users_updateAvatar, web_users_changeUserName, web_users_changePassword } from '@/assets/properties'

const { cookies } = useCookies()
const toast = useToast()
const crypto = inject('cryptojs')

const userInfo = userInfoStore()
const userAvatarUrl = computed(() => web_users_getAvatar
  + "?userId=" + cookies.get('userId') + "&token=" + cookies.get("token") + "&update=" + userInfo.update)
const noAvatarFlag = ref(false)

const newUsername = ref(userInfo.userName)
const oldPassword = ref('')
const newPassword = ref('')
const confirmPassword = ref('')

// check validity 
const validPassword = computed(() => {
  var res = newPassword.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})/)
  return res != null && res.length != 0
})
const passwordMatch = computed(() => {
  return (newPassword.value == '' || confirmPassword.value != '')
    && newPassword.value == confirmPassword.value
})
const showConfirmError = computed(() => {
  return passwordMatch.value
})
const showPasswordError = computed(() => {
  return (newPassword.value == '' || validPassword.value)
})
const enableChange = computed(() => {
  return validPassword.value && passwordMatch.value
})

// style
const chngUrnmBtnDisabled = computed(() => {
  return userInfo.userName == newUsername.value
})
const passwordError = computed(() => {
  return (newPassword.value == '' || validPassword.value) ? 'valid' : 'p-invalid'
})
const confirmPasswordError = computed(() => {
  return (passwordMatch.value) ? 'match' : 'p-invalid'
})

// actions
const uploadAvatar = (event) => {
  var axios = require('axios');
  var FormData = require('form-data');

  var data = new FormData()
  data.append('userId', cookies.get('userId'))
  data.append('token', cookies.get('token'))
  data.append('photo', event.files[0])
  var config = {
    method: 'post',
    url: web_users_updateAvatar,
    data: data
  }

  axios(config)
    .then((res) => {
      if (res.data.responseCode == 200) {
        userInfo.update += 1
        toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully changed avatar', life: 3000 });
      } else {
        toast.add({ severity: 'error', summary: 'Failed', detail: 'Failed changing avatar: ' + res.data.message, life: 3000 });
      }
    })
}
const changeUsername = () => {
  userInfo.userName = newUsername.value

  const axios = require('axios')
  const FormData = require('form-data');

  var data = new FormData()
  data.append('userId', cookies.get('userId'))
  data.append('token', cookies.get('token'))
  data.append('newUserName', newUsername.value)
  var config = {
    method: 'post',
    url: web_users_changeUserName,
    data: data
  }

  axios(config)
    .then(function (res) {
      if (res.data.responseCode == 200) {
        toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully changed username', life: 3000 });
      } else {
        toast.add({ severity: 'error', summary: 'Failed', detail: 'Failed changing username: ' + res.data.message, life: 3000 });
      }
    })
}
const changePassword = () => {
  const axios = require('axios')
  const FormData = require('form-data');

  var data = new FormData()
  data.append('userId', cookies.get('userId'))
  data.append('token', cookies.get('token'))
  data.append('oldPassword', crypto.SHA256(oldPassword.value).toString())
  data.append('newPassword', crypto.SHA256(newPassword.value).toString())
  var config = {
    method: 'post',
    url: web_users_changePassword,
    data: data
  }

  axios(config)
    .then(function (res) {
      if (res.data.responseCode == 200) {
        oldPassword.value = ''
        newPassword.value = ''
        confirmPassword.value = ''
        toast.add({ severity: 'success', summary: 'Success', detail: 'Successfully changed password', life: 3000 });
      } else {
        toast.add({ severity: 'error', summary: 'Failed', detail: 'Failed changing password: ' + res.data.message, life: 3000 });
      }
    })
}
const avatarError = () => {
  noAvatarFlag.value = true
}

</script>

<style lang="scss" scoped>
.wrapper {
  width: 50vw;
  height: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

#changePasswordWrapper {
  width: min-content;
}
</style>
