<template>
  <div>
    <!-- Toast -->
    <Toast position="bottom-right" />

    <!-- Title -->
    <h1>Sign Up</h1>

    <!-- Text fields -->
    <InputText type="email" class="w-full mb-2" placeholder="Email" v-model="email" :class="emailError" />
    <div class="flex">
      <div class="flex-grow-1" style="width: 1px;"></div>
      <small class="p-error" :hidden="showEmailError">Invalid Email address</small>
    </div>
    <Password class="w-full mt-2 mb-2" placeholder="Password" :style="{ width: '100 %' }" v-model="password"
      strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})" :class="passwordError" toggleMask>
      <template #footer>
        <pv-Divider />
        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
          <li>At least one lowercase</li>
          <li>At least one uppercase</li>
          <li>At least one numeric</li>
          <li>Minimum 8 characters</li>
          <li>Maximum 32 characters</li>
        </ul>
      </template>
    </Password>
    <div class="flex">
      <div class="flex-grow-1" style="width: 1px;"></div>
      <small class="p-error" :hidden="showPasswordError">Password doesn't meet requirement</small>
    </div>
    <Password class="w-full mt-2 mb-2" placeholder="Confirm password" :class="confirmPasswordError" :feedback="false"
      v-model="confirmPassword">
    </Password>
    <div class="flex">
      <div class="flex-grow-1" style="width: 1px;"></div>
      <small class="p-error" :hidden="showConfirmError">Passwords don't match</small>
    </div>
    <InputText type="text" class="w-full mt-2" placeholder="Username (optional)" v-model="userName" />

    <!-- action & navigation -->
    <div class="mt-4 w-full flex flex-row">
      <Button class="mr-2 w-full p-button-outlined" label="Go back" @click="discard"></Button>
      <Button class="ml-2 w-full" label="Sign Up" :disabled="!enableSignUp" @click="signup"></Button>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { computed } from "@vue/reactivity";
import { useCookies } from 'vue3-cookies'

import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

import { web_users_new } from "@/assets/properties";

onMounted(() => {
  document.title = "The CIR App Sign Up"
})

const router = useRouter();
const { cookies } = useCookies()
const axios = inject('axios')
var qs = require('qs')
const toast = useToast()
const crypto = inject('cryptojs')

const email = ref('')
const password = ref('');
const confirmPassword = ref('');
const userName = ref('')

// check parameters 
const validEmail = computed(() => {
  var res = email.value
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

  return res != null && res.length != 0
})
const validPassword = computed(() => {
  var res = password.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})/)
  return res != null && res.length != 0
})
const passwordMatch = computed(() => {
  return (password.value == '' || confirmPassword.value != '')
    && password.value == confirmPassword.value
})

// computed style
const emailError = computed(() => {
  return (email.value == '' || validEmail.value) ? 'valid' : 'p-invalid'
})
const passwordError = computed(() => {
  return (password.value == '' || validPassword.value) ? 'valid' : 'p-invalid'
})
const confirmPasswordError = computed(() => {
  return (passwordMatch.value) ? 'match' : 'p-invalid'
})
const showEmailError = computed(() => {
  return email.value == '' || validEmail.value
})
const showConfirmError = computed(() => {
  return passwordMatch.value
})
const showPasswordError = computed(() => {
  return (password.value == '' || validPassword.value)
})
const enableSignUp = computed(() => {
  return validEmail.value && validPassword.value && passwordMatch.value
})

const discard = () => {
  router.push("login");
};
const signup = () => {
  axios({
    method: 'post',
    url: web_users_new,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      userEmail: email.value,
      password: crypto.SHA256(password.value).toString(),
      userName: userName.value,
    })
  }).then((res) => {
    if (res.data.responseCode == 200) {
      // temporary store info, cleared at quit 
      cookies.set('userId', res.data.data.userId)
      cookies.set('token', res.data.data.token)

      router.replace("/dashboard");
    } else {
      toast.add({ severity: 'error', summary: 'Cannot sign up', detail: res.data.data, life: 3000 });
    }
  })
};
</script>

<style lang="scss" scoped>
.fullscreen {
  // minus browsers built-in body's 8px margin
  width: calc(100% - 8px);
  height: calc(100vh - 8px);
}

.p-inputtext {
  display: block;
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius);

  &:last-child {
    margin-bottom: 0;
  }
}

::v-deep(.p-password input) {
  width: 100%;
  border-radius: var(--border-radius);
}

.p-button {
  border-radius: var(--border-radius);
}
</style>
