<template>
  <main class="fullscreen grid">
    <!-- Toast -->
    <Toast position="bottom-right" />

    <!-- sidebar menu -->
    <div class="col-fixed shadow-2" id="sidebar">
      <SidebarMenu />
    </div>

    <!-- image flow -->
    <div class="col px-3 justify-content-center surface-200">
      <GalleryComponent />
    </div>
  </main>
</template>

<script setup>
import { inject, onMounted } from "vue";
import { useCookies } from "vue3-cookies";

import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

import SidebarMenu from "@/components/SidebarMenu.vue";
import GalleryComponent from "@/components/GalleryComponent.vue";
import { web_photo_list } from "@/assets/properties"

onMounted(() => {
  document.title = "The CIR App Web Portal"
})

const axios = inject('axios')
var qs = require('qs')
const store = useImgListStore();
const toast = useToast()

// fetch all photos
axios({
  method: 'post',
  url: web_photo_list,
  data: qs.stringify({
    userId: cookies.get('userId'),
    token: cookies.get('token'),
  })
}).then((res) => {
  if (res.data.responseCode == 200) {
    // clear the list
    store.list = []

    // add the fetched list of image to the store
    for (const img of res.data.data) {
      store.addImg({
        userId: img.userId,
        time: parseInt(img.createTime),
        imgId: img.imageId,
        CIR: img.cirRating,
        manual: img.manualRating,
        location: img.location,
        roomType: img.roomType,
        description: img.description,
        userName: img.userName,
      })
    }
  } else {
    toast.add({ severity: 'error', summary: 'Cannot fetch images', detail: res.data.data, life: 3000 });
  }
})
</script>

<script>
import { defineStore } from "pinia";

const { cookies } = useCookies()

export const useImgListStore = defineStore("imgList", {
  state: () => ({
    sortCriterion: "undef",
    filter: {
      rating: [1, 9],
      roomType: [],
      location: [],
      time: [],
      description: "",
      user: "All",
    },
    list: [],
    isAdmin: false,
  }),
  getters: {
    getIsAdmin: (state) => {
      return state.isAdmin;
    },
    getImgByImgId: (state) => {
      return (id) =>
        state.list.find((img) => {
          return img.info.imgId == id;
        });
    },
    getImgIndexByImgId: (state) => {
      return (id) =>
        state.list.findIndex((img) => {
          return img.info.imgId == id;
        });
    },
    filteredList: (state) =>
      state.list.filter(
        (img) =>
          (img.info.CIR >= state.filter.rating[0] &&
            img.info.CIR <= state.filter.rating[1]) &&
          (state.filter.roomType.length != 0
            ? state.filter.roomType.includes(img.info.roomType)
            : true) &&
          (state.filter.location.length != 0
            ? state.filter.location.includes(img.info.location)
            : true) &&
          (state.filter.time.length != 0
            ? new Date(img.info.time).getTime() >=
            new Date(state.filter.time[0]).getTime() &&
            new Date(img.info.time).getTime() <=
            new Date(state.filter.time[1]).getTime()
            : true) &&
          (state.filter.description.length != 0
            ? img.info.description
              .toLowerCase()
              .includes(state.filter.description.toLowerCase())
            : true) &&
          (state.filter.user === "All"
            // show all
            ? true
            : (state.filter.user === "Own")
              // show photos belongs to this account
              ? cookies.get('userId').includes(img.info.userId)
              // show photos belongs to other accounts
              : img.info.userId != cookies.get('userId'))
      ),
    getRoomTypeList: (state) => {
      var roomTypeSet = new Set();
      state.list.forEach((img) => roomTypeSet.add(img.info.roomType));

      // convert to list of {name: `roomType`}
      var roomTypeList = [];
      roomTypeSet.forEach((value) => roomTypeList.push({ name: value }));

      return roomTypeList;
    },
    getLocationList: (state) => {
      var locationSet = new Set();
      state.list.forEach((img) => locationSet.add(img.info.location));

      // convert to list of {name: `location`}
      var locationList = [];
      locationSet.forEach((value) => locationList.push({ name: value }));

      return locationList;
    },
  },
  actions: {
    // selection
    getSelected() {
      return this.filteredList.filter((img) => img.selected === true);
    },
    toggleSelection(id) {
      var temp = this.list.find((img) => img.info.imgId == id);
      temp.selected = !temp.selected;
    },
    selectAll() {
      this.filteredList.forEach((img) => {
        img.selected = true;
      });
    },
    clearSelection() {
      this.list.forEach((img) => {
        img.selected = false;
      });
    },
    selectInverse() {
      this.list.forEach((img) => {
        img.selected = !img.selected;
      });
    },

    // sorting
    sortByTime() {
      if (this.sortCriterion != "time") {
        // if not sorted by rating
        this.list.sort((a, b) => {
          let timeA = a.info.time
          let timeB = b.info.time
          if (timeA < timeB) {
            return -1;
          } else if (timeA > timeB) {
            return 1;
          } else {
            return 0;
          }
        });
        this.sortCriterion = "time";
      } else {
        // if already sorted by rating, reverse the list
        this.list.reverse();
      }
    },
    sortByLocation() {
      if (this.sortCriterion != "location") {
        this.list.sort((a, b) => {
          if (a.info.location < b.info.location) {
            return -1;
          } else if (a.info.location > b.info.location) {
            return 1;
          } else {
            return 0;
          }
        });
        this.sortCriterion = "location";
      } else {
        this.list.reverse();
      }
    },
    sortByRoomType() {
      if (this.sortCriterion != "roomType") {
        this.list.sort((a, b) => {
          if (a.info.roomType < b.info.roomType) {
            return -1;
          } else if (a.info.roomType > b.info.roomType) {
            return 1;
          } else {
            return 0;
          }
        });
        this.sortCriterion = "roomType";
      } else {
        this.list.reverse();
      }
    },
    sortByRating() {
      if (this.sortCriterion != "rating") {
        this.list.sort((a, b) => {
          if (a.info.CIR < b.info.CIR) {
            return -1;
          } else if (a.info.CIR > b.info.CIR) {
            return 1;
          } else {
            return 0;
          }
        });
        this.sortCriterion = "rating";
      } else {
        this.list.reverse();
      }
    },

    // image operations
    addImg(value) {
      if (!this.isAdmin && value.userId != cookies.get("userId")) {
        this.isAdmin = true
      }

      this.list.push({
        selected: false,
        info: {
          time: value.time,
          imgId: value.imgId,
          userId: value.userId,
          CIR: value.CIR,
          manual: value.manual,
          location: value.location,
          roomType: value.roomType,
          description: value.description,
          userName: value.userName,
        },
      });
    },
    genTestData(count) {
      const testLocation = [
        "100 Commonwealth Ave.",
        "700 Beacon St.",
        "175 Freeman St.",
        "44 Cummington Mall",
      ];
      const testRoomType = ["Bedroom", "Kitchen", "Living Room", "Balcony", "Bathroom"];

      for (var i = 0; i < count; ++i) {
        this.addImg({
          time: new Date(new Date() - Math.random() * 86400000 * 5).toDateString(), // random past 5 days
          imgId: i,
          CIR: Math.floor(Math.random() * 8) + 1,
          manual: Math.floor(Math.random() * 8) + 1,
          location: testLocation[Math.floor(Math.random() * testLocation.length)],
          roomType: testRoomType[Math.floor(Math.random() * testRoomType.length)],
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        });
      }
    },
  },
});
</script>

<style>
#sidebar {
  width: 250px;
}
</style>
