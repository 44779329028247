<template>
  <div class="forgot-password-view">
    <!-- Toast -->
    <Toast position="top-right" :breakpoints="{ '500px': { width: '100%', right: '0', left: '0' } }"></Toast>

    <!-- Content -->
    <div class="flex-column flex align-items-center m-5">
      <div class="wrapper">
        <div class="flex flex-row align-items-center">
          <img src="@/assets/logo.png">
          <p class="ml-2 font-bold text-600">Beaverly</p>
        </div>
        <p class="text-3xl font-bold">Forgot Password?</p>

        <!-- Email -->
        <div class="flex">
          <InputText type="email" placeholder="Email address" v-model="email" class="w-full border-round"
            :class="emailError">
          </InputText>
        </div>
        <div class="flex flex-row w-full">
          <div class="flex-grow-1" style="width: 1px;"></div>
          <small class="p-error" :hidden="showEmailError">Invalid Email address</small>
        </div>
        <div style="height: 1rem;" :hidden="!showEmailError"></div>

        <!-- Code -->
        <div class="flex flex-row mt-1">
          <InputMask v-model="rawCode" mask="9-9-9-9-9-9" placeholder="0-0-0-0-0-0" id="CodeLeft"></InputMask>
          <Button :label="label" class="w-screen" id="CodeRight" @click="getCode" :disabled="disableGetCode"></Button>
        </div>
        <div style="height: 1rem;"></div>

        <!-- New password -->
        <Password class="w-full" placeholder="New password" v-model="password"
          strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})" :class="passwordError" toggleMask>
          <template #footer>
            <pv-Divider />
            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>Minimum 8 characters</li>
              <li>Maximum 32 characters</li>
            </ul>
          </template>
        </Password>
        <div class="flex">
          <div class="flex-grow-1" style="width: 1px;"></div>
          <small class="p-error" :hidden="showPasswordError">Password doesn't meet requirement</small>
        </div>
        <div style="height: 1rem;"></div>

        <!-- Confirm password -->
        <InputText class="w-full border-round" placeholder="Confirm password" type="password"
          :class="confirmPasswordError" v-model="confirmPassword">
        </InputText>
        <div class="flex">
          <div class="flex-grow-1" style="width: 1px;"></div>
          <small class="p-error" :hidden="showConfirmError">Passwords don't match</small>
        </div>
        <div style="height: 1rem;" :hidden="!showConfirmError"></div>

        <!-- Reset -->
        <Button class="mt-6 w-full border-round" label="Reset" @click="reset"></Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref, onMounted, onUnmounted } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useRouter } from "vue-router";
import FormData from 'form-data';

import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';

import { getPasswordResetCode, passwordReset} from '@/assets/properties';

const interval = 60; // time interval between two getCode operations in second
const { cookies } = useCookies();
const toast = useToast();
const crypto = inject('cryptojs');
const axios = inject('axios'); // does not support TS yet
const router = useRouter();


const email = ref('');
const rawCode = ref('');
const code = computed(() => rawCode.value.replaceAll('-', ''));
const password = ref('');
const confirmPassword = ref('');
const disableGetCode = ref(true);
const label = ref('Get Code');
let diff = 1;
let intervalId = null;

// update getCode button every 0.2 second
onMounted(() => {
  intervalId = setInterval(() => {
    diff = Math.floor((Date.now() - parseFloat(cookies.get('getCodeTime'))) / 1000);
    disableGetCode.value = (diff < interval) && (diff > 0);
    if (disableGetCode.value) {
      label.value = (interval - diff) + 's';
    } else {
      label.value = 'Get Code';
    }
  }, 200);
});

onUnmounted(() => {
  if (intervalId !== null) {
    clearInterval(intervalId);
  }
});

// check parameters 
const validEmail = computed(() => {
  const res = email.value
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\].[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return res != null && res.length != 0;
});
const validPassword = computed(() => {
  const res = password.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,32})/);
  return res != null && res.length != 0;
});
const passwordMatch = computed(() => {
  return (password.value == '' || confirmPassword.value != '') && password.value == confirmPassword.value;
});

// computed style
const emailError = computed(() => {
  return (email.value == '' || validEmail.value) ? 'valid' : 'p-invalid';
});
const passwordError = computed(() => {
  return (password.value == '' || validPassword.value) ? 'valid' : 'p-invalid';
});
const confirmPasswordError = computed(() => {
  return (passwordMatch.value) ? 'match' : 'p-invalid';
});
const showEmailError = computed(() => {
  return email.value == '' || validEmail.value;
});
const showConfirmError = computed(() => {
  return passwordMatch.value;
});
const showPasswordError = computed(() => {
  return (password.value == '' || validPassword.value);
});

// Actions
const getCode = () => {
  if (validEmail.value && email.value.length != 0) {
    cookies.set('getCodeTime', Date.now().toString());
    disableGetCode.value = true;

    const config = {
      method: 'get',
      url: getPasswordResetCode + '?userEmail=' + email.value
    };

    axios(config)
      .then((response) => {
        console.log(response);
        if (response.data.responseCode != 200) {
          toast.add({ severity: 'error', summary: 'Error', detail: response.data.data, life: 3000 });
          cookies.set('getCodeTime', '0');
        } else {
          toast.add({ severity: 'success', summary: 'Code sent', detail: "Please check your email", life: 3000 });
        }
      });
  } else {
    toast.add({ severity: 'error', summary: 'Invalid email', detail: "Please enter valid email address", life: 3000 });
  }
};
const reset = () => {
  if (validEmail.value && validPassword.value && code.value.length == 6 && passwordMatch.value) {
    const data = new FormData();
    data.append('userEmail', email.value);
    data.append('resetCode', code.value);
    data.append('password', crypto.SHA256(password.value).toString());

    const config = {
      method: 'post',
      url: passwordReset,
      data: data
    };

    axios(config)
      .then((response) => {
        if (response.data.responseCode == 200) {
          toast.add({ severity: 'success', summary: 'Reset Successful', detail: "redirect to the login page...", life: 2000 });

          // jump to the login page in 2sec
          setTimeout(() => {
            router.push('/auth/login'); 
          }, 2000);
        } else {
          toast.add({ severity: 'error', summary: 'Error', detail: response.data.data, life: 3000 });
        }
      });
  } else {
    toast.add({ severity: 'error', summary: 'Invalid input', detail: "Please examine that all fields are valid", life: 3000 });
  }
};
</script>


<style scoped>

img {
  width: var(--logo-size);
  height: var(--logo-size);
  border-radius: 50%;
  object-fit: cover;
}

#CodeLeft {
  border-radius: var(--border-radius);
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#CodeRight {
  border-radius: var(--border-radius);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.p-inputtext.p-component.p-password-input {
  width: 100%;
  border-radius: var(--border-radius);
}

.wrapper {
  max-width: 298px;
  position: absolute;
  top: 10%;
}
</style>
