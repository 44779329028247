<template>
  <main>
    <Chart type="polarArea" :data="locationAvgCIR" :options="pieChartOptions"></Chart>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import Chart from 'primevue/chart'

import { useImgListStore } from '@/views/DashboardPage.vue';

// location avg CIR
const imgStore = useImgListStore();
const locationList = ref([]);
const cirList = ref([]);

imgStore.getLocationList.forEach((location) => {
  var total = 0;    // total CIR
  var count = 0;    // number of images of this location
  locationList.value.push(location["name"])
  imgStore.list.forEach((img) => {
    if (img.info.location.includes(location["name"])) {
      total += img.info.CIR
      count += 1
    }
  })

  cirList.value.push(total / count)
})

// generate random color
const colorList = ref([])
for (var i = 0; i < locationList.value.length; ++i) {
  colorList.value.push("#" + Math.floor(Math.random() * 16777215).toString(16));
}

const locationAvgCIR = ref({
  datasets: [{
    data: cirList,
    backgroundColor: colorList,
    label: 'Avg CIR'
  }],
  labels: locationList
});

const pieChartOptions = ref(
  {
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      r: {
        grid: {
          color: '#ebedef'
        }
      }
    }
  }
);
</script>

<style scoped>
main {
  height: 70vh;
  width: 70vh;
}
</style>
