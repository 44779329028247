<template>
  <div class="flex flex-column h-full">
    <ConfirmDialog></ConfirmDialog>
    <Toast position="bottom-right" />
    <!-- Modal display -->
    <!-- user profile -->
    <Dialog v-model:visible="userProfileDisplay" :modal="true">
      <!-- this put the close button to the right -->
      <template #header>
        <div style="width: 1px"></div>
      </template>
      <UserPage></UserPage>
    </Dialog>
    <!-- stats -->
    <Dialog v-model:visible="statsDisplay" :modal="true">
      <!-- this put the close button to the right -->
      <template #header>
        <div style="width: 1px"></div>
      </template>
      <StatsComponent></StatsComponent>
    </Dialog>

    <!-- logo header -->
    <div class="flex-none flex flex-row justify-content-start align-content-center m-auto">
      <img class="col-3 border-circle align-self-center" src="@/assets/logo.png" />
      <h2>The CIR App</h2>
    </div>

    <!-- content -->
    <ScrollPanel class="flex-grow-1 w-full" id="content">
      <Accordion class="mb-1" collapseIcon expandIcon>
        <AccordionTab>
          <template #header>
            <i class="pi pi-filter mr-2"></i>
            <span>Filter</span>
          </template>

          <!-- filters -->
          <div class="w-full" id="filter">
            <h5>Rating: {{ filterRating }}</h5>
            <Slider v-model="filterRating" :min="1" :max="9" :range="true"></Slider>

            <h5>Room type</h5>
            <MultiSelect placeholder="Select room types" v-model="filterRoomTypes" :options="roomTypes" :filter="true"
              optionLabel="name" class="multiselect-custom w-full">
            </MultiSelect>

            <h5>Location</h5>
            <MultiSelect placeholder="Select locations" v-model="filterLocation" :options="locations" :filter="true"
              optionLabel="name" class="multiselect-custom w-full">
            </MultiSelect>

            <h5>Time</h5>
            <Calendar v-model="filterTime" selectionMode="range" :manualInput="false" date-format="MM dd yy"></Calendar>

            <h5>Description</h5>
            <InputText class="w-full" type="text" v-model="filterDescription"></InputText>

            <div class="w-full" :hidden="!isAdmin">
              <h5>User</h5>
              <Dropdown class="w-full" v-model="filterUser" :options="userOptions"></Dropdown>
            </div>

            <div class="flex flex-row w-full mt-3">
              <Button class="w-full mr-1 p-button-outlined" label="Clear" @click="clearFilter"></Button>
              <Button class="w-full ml-1" label="Apply" @click="applyFilter"></Button>
            </div>
          </div>
        </AccordionTab>
      </Accordion>

      <PanelMenu :model="menuItems"></PanelMenu>
    </ScrollPanel>

    <!-- footer -->
    <pv-Divider></pv-Divider>
    <Menu id="user_menu" ref="showUserMenu" :model="userMenuItems" :popup="true" />
    <div class="flex-none flex flex-row">
      <!-- avatar click wrapper-->
      <div @click="toggleUserMenu" aria-haspopup="true" aria-controls="user_menu">
        <div :hidden="!noAvatar">
          <Avatar type="button" icon="pi pi-user" size="large" />
        </div>
        <img class="border-round align-self-center" id="avatar" :src="userAvatarUrl" :onerror="avatarError"
          :hidden="noAvatar" />
      </div>
      <p class="ml-3">{{ userInfo.userName }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";

import Button from "primevue/button";
import PanelMenu from "primevue/panelmenu";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Slider from "primevue/slider";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import ScrollPanel from "primevue/scrollpanel";
import InputText from "primevue/inputtext";
import Dialog from 'primevue/dialog'
import { useConfirm } from 'primevue/useconfirm'
import ConfirmDialog from "primevue/confirmdialog";
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import Dropdown from 'primevue/dropdown';

import { useImgListStore } from "@/views/DashboardPage.vue";
import { computed } from "@vue/reactivity";
import { web_users_getAvatar, web_photo_delete } from "@/assets/properties";
import UserPage from "@/components/UserPage.vue";
import StatsComponent from "./StatsComponent.vue";

// components declaration
const router = useRouter();
const imgListStore = useImgListStore();
const userInfo = userInfoStore()
userInfo.fetchUserName()
const { cookies } = useCookies()
const confirm = useConfirm();
const axios = inject('axios')
const toast = useToast()

// modal controller
const userProfileDisplay = ref(false)
const statsDisplay = ref(false)

// variables
const noAvatar = ref(false)
const userAvatarUrl = computed(() => web_users_getAvatar
  + "?userId=" + cookies.get('userId') + "&token=" + cookies.get("token") + "&update=" + userInfo.update)
const isAdmin = computed(() => imgListStore.getIsAdmin)

// sort options style
const sortButtonStyle = (criterion) =>
  computed(() => {
    return imgListStore.sortCriterion === criterion
      ? "background-color: #F5DCAF; \
           border-radius: var(--border-radius)"
      : "background-color: white; \
           border-radius: var(--border-radius)";
  });

// menu lists
const menuItems = ref([
  {
    key: "0",
    icon: "pi pi-sort-alt",
    label: "Sort",
    items: [
      {
        key: "0_0",
        icon: "pi pi-clock",
        label: "Time",
        style: sortButtonStyle("time"),
        command: () => imgListStore.sortByTime(),
      },
      {
        key: "0_1",
        icon: "pi pi-map-marker",
        label: "Location",
        style: sortButtonStyle("location"),
        command: () => imgListStore.sortByLocation(),
      },
      {
        key: "0_2",
        icon: "pi pi-home",
        label: "Room Type",
        style: sortButtonStyle("roomType"),
        command: () => imgListStore.sortByRoomType(),
      },
      {
        key: "0_3",
        icon: "pi pi-star",
        label: "Rating",
        style: sortButtonStyle("rating"),
        command: () => imgListStore.sortByRating(),
      },
    ],
  },
  {
    key: "1",
    icon: "pi pi-check-circle",
    label: "Select",
    items: [
      {
        key: "1_0",
        icon: "pi pi-check",
        label: "Select all",
        command: () => imgListStore.selectAll(),
      },
      {
        key: "1_1",
        icon: "pi pi-ban",
        label: "Clear selection",
        command: () => imgListStore.clearSelection(),
      },
      {
        key: "1_2",
        icon: "pi pi-circle",
        label: "Select inverse",
        command: () => imgListStore.selectInverse(),
      },
    ],
  },
  {
    key: "2",
    icon: "pi pi-download",
    label: "Download",
    disabled: computed(() => imgListStore.getSelected().length == 0),
    command: () => downloadSelected(),
  },
  {
    key: "3",
    icon: "pi pi-trash",
    label: "Delete",
    disabled: computed(() => imgListStore.getSelected().length == 0),
    command: () => deleteSelected(),
  },
]);
const showUserMenu = ref(null);
const userMenuItems = ref([
  {
    label: "User Info",
    items: [
      {
        label: "Profile",
        icon: "pi pi-user",
        command: () => { userProfileDisplay.value = true }
      },
      {
        label: "Stats",
        icon: "pi pi-chart-bar",
        command: () => { statsDisplay.value = true }
      },
      {
        label: "Log out",
        icon: "pi pi-power-off",
        command: () => logOut(),
      },
    ],
  },
]);

// filter options
const filterRating = ref([1, 9]);
const filterRoomTypes = ref([]);
const roomTypes = computed(() => imgListStore.getRoomTypeList);
const filterLocation = ref([]);
const locations = computed(() => imgListStore.getLocationList);
const filterTime = ref([]);
const filterDescription = ref("");
const filterUser = ref("All")
const userOptions = ref(['All', 'Own', 'Others'])

// actions
const toggleUserMenu = (event) => {
  showUserMenu.value.toggle(event);
};
const clearFilter = () => {
  filterRating.value = [1, 9];
  filterRoomTypes.value = [];
  filterLocation.value = [];
  filterTime.value = [];
  filterDescription.value = "";
  filterUser.value = "All";
  applyFilter();
};
const applyFilter = () => {
  imgListStore.filter.rating = filterRating.value;

  // extract room types from JSON list
  var roomTypeList = [];
  filterRoomTypes.value.forEach((value) => roomTypeList.push(value["name"]));
  imgListStore.filter.roomType = roomTypeList;

  // extract location from JSON list
  var locationList = [];
  filterLocation.value.forEach((value) => locationList.push(value["name"]));
  imgListStore.filter.location = locationList;

  imgListStore.filter.time = filterTime.value;

  imgListStore.filter.description = filterDescription.value;

  imgListStore.filter.user = filterUser.value;
};
const downloadSelected = () => {
  var imgIdList = [];
  imgListStore.getSelected().forEach((img) => imgIdList.push(img.info.imgId));

  const axios = require('axios');
  let config = {
    method: 'get',
    url: web_photo_batchDownload + "?userId=" + cookies.get("userId")
      + "&token=" + cookies.get("token") + "&photoIds=" + imgIdList,
    responseType: 'arraybuffer'
  };

  axios(config)
    .then((response) => {
      if (response.status == 200) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        var time = new Date()
        // var fileName = time.getHours() + "-" + time.getMinutes() + "-" + imgIdList.length + "photos.zip"
        var formattedTime = time.getFullYear() + "-" + 
                    ("0" + (time.getMonth() + 1)).slice(-2) + "-" + 
                    ("0" + time.getDate()).slice(-2) + "-" + 
                    ("0" + time.getHours()).slice(-2) + "-" + 
                    ("0" + time.getMinutes()).slice(-2) + "-" + 
                    ("0" + time.getSeconds()).slice(-2);
        var fileName = "CIR_image_batch_" + formattedTime + ".zip";
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      } else {
        toast.add({ severity: 'error', summary: 'Download error', detail: response.data.data, life: 3000 });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const deleteSelected = () => {
  var imgIdList = [];
  imgListStore.getSelected().forEach((img) => imgIdList.push(img.info.imgId));

  confirm.require({
    header: "Confirm Deletion",
    message: "Are you sure you want to delete?",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "p-button-danger",
    accept: () => {
      for (const id of imgIdList) {
        imgListStore.list.splice(imgListStore.getImgIndexByImgId(id), 1)

        var data = new FormData();
        data.append('userId', cookies.get('userId'));
        data.append('token', cookies.get('token'));
        data.append('photoId', id);
        let config = {
          method: 'delete',
          url: web_photo_delete,
          data: data
        }

        axios(config)
          .then((res) => {
            if (res.data.responseCode != 200) {
              console.error("Failed to delete " + id)
            } else {
              toast.add({ severity: 'error', summary: 'Deletion error', detail: res.data.data, life: 3000 });
            }
          })
          .catch(function (res) {
            console.error("Error when deleting " + id)
            console.error(res)
          });
      }
    },
    reject: () => {
    },
  });
};
const logOut = () => {
  cookies.remove('token')
  cookies.remove('userId')
  router.replace("/auth");
};
const avatarError = () => {
  noAvatar.value = true
}
</script>
<script>
import { useCookies } from "vue3-cookies";
import { web_photo_batchDownload, web_users_getUserName } from "@/assets/properties"

// user info store
export const userInfoStore = defineStore('userInfo', {
  state: () => ({
    update: 0,     // <img> re-render when this ref changes
    userName: 'The CIR App User',
  }),
  actions: {
    fetchUserName() {
      const axios = require('axios')
      const FormData = require('form-data')
      const { cookies } = useCookies()

      var data = new FormData()
      data.append('userId', cookies.get('userId'))
      data.append('token', cookies.get('token'))
      var config = {
        method: 'post',
        url: web_users_getUserName,
        data: data,
      }

      axios(config)
        .then((res) => {
          if (res.data.responseCode == 200) {
            this.userName = res.data.data
          } else {
            console.error("Get username failed")
            console.error(res)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
})
</script>

<style lang="scss">
#avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.p-component {
  font-family: "Ubuntu" !important;
}

.p-panelmenu .p-panelmenu-panel {
  box-shadow: none !important;
}

.p-panelmenu .p-submenu-list {
  padding-left: 1rem !important;
}

.p-sidebar .p-sidebar-header {
  padding: 0;
}

.p-sidebar-content {
  overflow-y: off;
}

.p-accordion-content {
  padding-top: 0;
}

h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#content {
  height: calc(100vh - 180px);
}
</style>
