// export const serverAddr = "https://api.beaverly.net:8443"
export const serverAddr = "https://api.cir-app.com:8443"
// export const serverAddr = "http://localhost:23333"


// users
export const web_users_login = serverAddr + "/web/user/login"
export const web_users_new = serverAddr + "/web/user/new"
export const web_users_getUserName = serverAddr + "/web/user/getUserName"
export const web_users_changeUserName = serverAddr + "/web/user/changeUserName"
export const web_users_getAvatar = serverAddr + "/web/user/photo/download"
export const web_users_updateAvatar = serverAddr + "/web/user/photo/update"
export const web_users_changePassword = serverAddr + "/web/user/changePassword"
export const web_users_tokenValidate = serverAddr + "/web/user/tokenValidate"
export const getPasswordResetCode = serverAddr + "/web/user/getPasswordResetCode"
export const passwordReset = serverAddr + "/web/user/passwordReset"

// photo
export const web_photo_list = serverAddr + "/web/photo/list"
export const web_photo_update = serverAddr + "/web/photo/update"
export const web_photo_download = serverAddr + "/web/photo/download"
export const web_photo_batchDownload = serverAddr + "/web/photo/batchDownload"
export const web_photo_delete = serverAddr + "/web/photo/delete"
