<template>
  <ScrollPanel id="wrapper">
    <div class="grid">
      <div class="col-2" v-for="img in imgList" :key="img.info.imgId">
        <ImageComponent :data="img" />
      </div>
    </div>
  </ScrollPanel>
</template>

<script setup>
import ScrollPanel from "primevue/scrollpanel";
import ImageComponent from "@/components/ImageComponent.vue";
import { useImgListStore } from "@/views/DashboardPage.vue";
import { computed } from "@vue/reactivity";

const imgListStore = useImgListStore();
const imgList = computed(() => imgListStore.filteredList);
</script>

<style lang="scss" scoped>
#wrapper {
  height: calc(100vh - 20px);
}
</style>
