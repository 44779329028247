<template>
  <div>
    <!-- Toast -->
    <Toast position="bottom-right" />

    <!-- Title -->
    <h1>Log in</h1>

    <!-- TextInput Fields -->
    <InputText id="email" type="email" class="w-full mt-2 mb-3" placeholder="Email" v-model="email" />
    <InputText id="password" type="password" class="w-full mb-3" placeholder="Password" v-model="password"
      @keydown.enter="login" />
    <!-- Tools -->
    <div class="flex flex-row align-items-center justify-content-between mb-6 px-1">
      <div class="flex align-items-center">
        <CheckBox id="rememberme" :binary="true" v-model="rememberme" class="mr-2"></CheckBox>
        <label for="rememberme" class="ubuntu">Remember me</label>
      </div>
      <div>
        <a @click.prevent="forgotPassword" class="font-medium no-underline ml-2 text-indigo-500 text-right cursor-pointer">Forgot password?</a>
      </div>
    </div>

    <!-- Buttons -->
    <Button class="w-full mb-2" label="Login" @click="login" :disabled="!enableLogin"></Button>
    <pv-Divider align="center">
      <p class="ubuntu">or</p>
    </pv-Divider>
    <Button class="w-full p-button-outlined" label="Signup" @click="signup"></Button>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from 'vue3-cookies'

import { web_users_login } from '@/assets/properties'

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import CheckBox from "primevue/checkbox";
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { computed } from "@vue/reactivity";

onMounted(() => {
  document.title = "The CIR App Login"
})

// globle
const router = useRouter();
const { cookies } = useCookies()
const axios = inject('axios')
var qs = require('qs')
const toast = useToast()
const crypto = inject('cryptojs')

// refs
const rememberme = ref();
const email = ref('');
const password = ref('')

// computed style
const enableLogin = computed(() => {
  return email.value != '' && password.value != ''
})

if (cookies.isKey('email')) {
  email.value = cookies.get('email')
  rememberme.value = true
}

const login = () => {
  if (rememberme.value == true) {
    cookies.set('email', email.value)
  } else {
    cookies.remove('email')
  }

  axios({
    method: 'post',
    url: web_users_login,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      userEmail: email.value,
      password: crypto.SHA256(password.value).toString(),
    })
  }).then((res) => {
    if (res.data.responseCode == 200) {
      // temporary store info, cleared at quit 
      cookies.set('userId', res.data.data.userId)
      cookies.set('token', res.data.data.token)

      router.replace("/dashboard");
    } else {
      toast.add({ severity: 'error', summary: 'Cannot login', detail: res.data.data, life: 3000 });
    }
  })

};
const signup = () => {
  router.push("signup");
};

const forgotPassword = () => {
  router.push("/forgot-password"); 
};
</script>

<style lang="scss" scoped>
.fullscreen {
  // minus browsers built-in body's 8px margin
  width: calc(100% - 8px);
  height: calc(100vh - 8px);
}

.p-inputtext {
  display: block;
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius);

  &:last-child {
    margin-bottom: 0;
  }
}

.p-button {
  border-radius: var(--border-radius);
}
</style>
